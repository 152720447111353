// ContactUs.js
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactUs.css'; // Import the CSS file

export const ContactUs = () => {
  const form = useRef();
  const [isSent, setIsSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs.sendForm('service_wp0tluk', 'template_v0zojem', form.current, 'ezZ_m2lvIeAbcOZBv')
      .then((result) => {
        console.log(result.text);
        setIsSent(true);
        alert('Sõnum on edukalt saadetud!');
      })
      .catch((error) => {
        console.log(error.text);
        alert('Failed to send message. Please try again later.');
      });
  };
  

  return (
    <div className="contact-form-container">
      <form ref={form} onSubmit={sendEmail}>
        <label>Nimi</label>
        <input type="text" name="user_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Sõnum</label>
        <textarea name="message" />
        <input type="submit" value="Saada" />
      </form>

      {isSent && <div className="success-message">Teie sõnum on edukalt saadetud!</div>}
    </div>
  );
};

export default ContactUs;
