import React from 'react';
import './Footer.css';
import logo from './images/NordByteLogo.png'; // Replace with your actual logo path

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src={logo} alt="NordByte Logo" />
          </div>
          <div className="footer-details">
            <p>&copy; {new Date().getFullYear()} NordByte</p>
            <p>Address: Tartu, Estonia</p>
            <p>Email: tanel@nordbyte.com</p>
            <p>Phone: +372 523 9545</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
