import React, { useEffect } from 'react';

const FacebookMessenger = () => {
  useEffect(() => {
    // Load the SDK asynchronously
    window.fbAsyncInit = function () {
      window.FB.init({
        xfbml: true,
        version: 'v18.0',
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  return (
    <div>
      {/* Facebook Messenger Chat Plugin */}
      <div
        className="fb-customerchat"
        page_id="122096579072016703" // Replace with your Page ID
        attribution="biz_inbox"
      ></div>
    </div>
  );
};

export default FacebookMessenger;
