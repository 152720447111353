
import React from 'react';
import { Element } from 'react-scroll';
import { Fade } from 'react-reveal';
import Reveal from 'react-reveal/Reveal';
import { FaCode, FaPaintBrush, FaSearch, FaDesktop, FaPalette, FaUser,  } from 'react-icons/fa';
import NewWorkProcessSection from './NewWorkProcessSection';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Contact from './ContactUs';  // Assuming 'Contact.js' is in the same directory
import NordByteLogo from './images/NordByteLogo.png';
import FacebookMessenger from './FacebookMessenger';
import './App.css';
import image from './images/7475603.png';
import Navigation from './Navigation'; // Import the Navigation component
import Footer from './Footer';


const App = () => {
  const servicesData = [
    {
      id: 1,
      title: "Veebiarendus",
      description: "Kvaliteetsed veebirakendused kaasaegsete tehnoloogiate abil.",
      icon: <FaCode style={{ color: '#a569bd' }} />, // Starting color
    },
    {
      id: 2,
      title: "Graafiline disain",
      description: "Loov graafiline disain, mis rõhutab teie sõnumit.",
      icon: <FaPaintBrush style={{ color: '#9851b4' }} />, // Darker purple shade
    },
    {
      id: 3,
      title: "SEO optimeerimine",
      description: "Parandage oma veebisaiti, et see oleks nähtav otsingumootorites.",
      icon: <FaSearch style={{ color: '#7e3a9d' }} />, // Even darker purple shade
    },
    {
      id: 4,
      title: "Veebilehtede hooldus",
      description: "Pidev tugi ja hooldus teie veebilehtedele.",
      icon: <FaDesktop style={{ color: '#662d8a' }} />, // Darker purple shade
    },
    {
      id: 5,
      title: "Logo kujundus",
      description: "Unikaalne logo, mis räägib teie brändi lugu.",
      icon: <FaPalette style={{ color: '#531e72' }} />, // Another darker purple shade
    },
    {
      id: 6,
      title: "UI/UX disain",
      description: "Kasutajasõbralik ja kaasaegne kasutajaliidese kujundus.",
      icon: <FaUser style={{ color: '#3a0f59' }} />, // Darkest purple shade
    },
  ];


  return (
    <div className="app-container">
    <Navigation />
      <Element name="landing" className="section landing">
  <div className="section-container landing-container">
    <div className="shapes">
      <div className="square square1" style={{ top: '10%', left: '20%' }}></div>
      <div className="circle" style={{ top: '30%', left: '65%' }}></div>
      <div className="square square2" style={{ top: '60%', left: '10%' }}></div>
    </div>
    <Fade bottom duration={1000} delay={500}>
      <div className="caption">
        <h2 className="section-heading">TERE TULEMAST NORDBYTE KODULEHELE</h2>
        <p className="section-text">
        <Reveal effect="slideInLeft">
  <span className="drop-cap">N</span>
</Reveal>
          ORDBYTE on Eesti veebiarendusettevõte, kes pakub tipptasemel veebirakenduste lahendusi.
        </p>
      </div>
    </Fade>
  </div>
</Element>
<FacebookMessenger />
<Element name="about" className="section about">
  <div className="section-container">
    <Fade bottom duration={1000} delay={500}>
      <div className="left-section">
        <h2 className="section-heading">
          <span className="rotate-meist">Meist</span>
        </h2>
        <p>
          Meie meeskond koosneb kogenud arendajatest ja disaineritest, kes loovad ainulaadseid veebilahendusi.
          NordByte loodi 2023. aastal visiooniga pakkuda kvaliteetseid digilahendusi. Meie keskmes on
          alati olnud klientide vajaduste mõistmine ja nende ootuste ületamine. Meie põhiväärtused -
          innovatsioon, ausus ja pühendumus - on aidanud meil saavutada usaldusväärse partneri maine
          digimaailmas.
        </p>
        <div className="about-content">
          <p className="about-text">
            Meie veebirakendused on loodud kaasaegsete tehnoloogiate abil, sealhulgas React, Node.js ja paljud teised.
            Pakume kohandatud lahendusi vastavalt klientide vajadustele ja keskendume alati kasutajakogemuse parandamisele.
            Oleme uhked oma töö üle ja loome digitaalset kunsti igal sammul.
          </p>
          <button className="quote-button">Saada päring</button>
        </div>
      </div>
      <div className="right-section">
        <img src={image} alt="Our Team" className="rotated-image" />
      </div>
    </Fade>
  </div>
</Element>
<Element name="services" className="section services">
  <div className="section-container">
    <Fade bottom duration={1000} delay={500}>
      <h2 className="section-heading">Teenused</h2>
    </Fade>
    <div className="services-grid">
      {servicesData.map((service) => (
        <div key={service.id} className="service">
          <Fade bottom duration={1000} delay={500}>
            <div className="service-icon" style={{ color: service.iconColor }}>
              {service.icon}
            </div>
          </Fade>
          <Fade bottom duration={1000} delay={500}>
            <h3>{service.title}</h3>
          </Fade>
          <Fade bottom duration={1000} delay={500}>
            <p>{service.description}</p>
          </Fade>
        </div>
      ))}
    </div>
  </div>
</Element>
 <Element name="newWorkProcess">
        <NewWorkProcessSection />
      </Element>
      <Element name="contact" className="section contact">
  <div className="section-container">
    <Fade bottom duration={1000} delay={500}>
      <div className="contact-header">
      <img src={NordByteLogo} alt="Company Logo" className="company-logo" />

      <div className="contact-info">
              <div className="info-item">
                <h3 className="info-text">Projektijuht - Tanel Takk</h3>
              </div>
              <div className="info-item">
                <h3 className="info-text">
                  E-post: <a href="mailto:tanel@nordbyte.com">tanel@nordbyte.com</a>
                </h3>
              </div>
              <div className="info-item">
                <h3 className="info-text">
                  Telefon: <a href="tel:+3725239545">+372 523 9545</a>
                </h3>
          </div>
        </div>
      </div>
      <Contact />
    </Fade>
  </div>
  
</Element>
 <Footer />
    </div>
  );
};

export default App;
