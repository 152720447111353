import React from 'react';
import { Fade } from 'react-reveal';
import { Link } from 'react-scroll';
import './NewWorkProcessSection.css';

const NewWorkProcessSection = () => {
  const titleWords = ["VEEBILEHE", "LOOMISE", "PROTSESS"];

  return (
    <div className="work-process-section" id="work-process-section">
      <Fade delay={500}>
        <div className="section-title">
          {titleWords.map((word, wordIndex) => (
            <span key={wordIndex} className="word-container">
              {word.split('').map((char, charIndex) => (
                <Fade key={charIndex} delay={1000 * (wordIndex)}>
                  <span className="glow-on-hover">{char}</span>
                </Fade>
              ))}
              {wordIndex < titleWords.length - 1 && <span>&nbsp;</span>}
            </span>
          ))}
        </div>
      </Fade>
      <div className="process-steps">
        <Fade bottom cascade delay={1000}>
          <div className="step">
            <div className="step-number">1</div>
            <p className="step-description">
              Konsultatsioon. Vaatame üle teie vajadused, et saaksime luua just vajadustele vastava veebilehe.
            </p>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <p className="step-description">
              Disaini, struktuur, elemendid. Vastavalt teie soovidele pakume välja erinevaid disani lahendusi ning püüame luua teile mugava ja sobiva veebilehe.
            </p>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <p className="step-description">
              Kui eelnevad sammud on kõik paigas, siis alustame koodi kirjutamist. Selles etapis kaasame ka teid ning anname teile ülevaateid sellest, milline teie veebileht parasjagu välja näeb.
            </p>
          </div>
          <div className="step">
            <div className="step-number">4</div>
            <p className="step-description">
              Viimases etapis testime teie veebilehte, otsime võimalikud vead ning parandame need. Lisaks toimub kodulehe optimeerimine.
            </p>
          </div>
        </Fade>
      </div>
      <Link to="work-process-section" smooth={true}>
       
      </Link>
    </div>
  );
};

export default NewWorkProcessSection;
