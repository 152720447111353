import React, { useState, useEffect } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import './Navbar.css'; // Import your CSS file

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  // Update the active section when scrolling
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const sections = ['landing', 'about', 'services', 'process', 'contact'];

      for (let i = sections.length - 1; i >= 0; i--) {
        const section = sections[i];
        const element = document.getElementById(section);

        if (element && scrollY >= element.offsetTop - 100) {
          // You can add code here to apply styles for the active section if needed
          break;
        }
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${isOpen ? 'active' : ''}`}>
      <div className="container">
        <div className="navbar-logo" onClick={() => scroll.scrollToTop()}>
          NordByte
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}> {/* Bind the isOpen state here */}
        <div className={`bar ${isOpen ? 'active' : ''}`}></div>
        <div className={`bar ${isOpen ? 'active' : ''}`}></div>
        <div className={`bar ${isOpen ? 'active' : ''}`}></div>
        </div>
        <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
            <Link
              to="landing"
              spy={true}
              smooth={true}
              duration={500}
              onClick={closeMenu}
              activeClass="active"
              offset={-70}
            >
              Avaleht
            </Link>
            <Link
              to="about"
              spy={true}
              smooth={true}
              duration={500}
              onClick={closeMenu}
              activeClass="active"
              offset={-70}
            >
              Meist
            </Link>
            <Link
              to="services"
              spy={true}
              smooth={true}
              duration={500}
              onClick={closeMenu}
              activeClass="active"
              offset={-70}
            >
              Teenused
            </Link>
            <Link
              to="newWorkProcess"
              spy={true}
              smooth={true}
              duration={500}
              onClick={closeMenu}
              activeClass="active"
              offset={-70}
            >
              Lähemalt
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              onClick={closeMenu}
              activeClass="active"
              offset={-70}
            >
              Kontakt
            </Link>
          </div>
        </div>
      </nav>
    );
  };
  
  export default Navigation;